var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-banner',[_c('div',{staticClass:"d-flex justify-space-between pr-4"},[_c('p',{staticClass:"text-h5 font-weight-medium mb-0"},[_vm._v(" Contabilidad de Donaciones ")])])]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[(_vm.searchInArray(_vm.actions, 'Ver'))?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.data.headers,"items":_vm.data.data,"item-per-page":5,"search":_vm.search,"loading":_vm.data.load,"loading-text":"Cargando datos...","sort-by":"group"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" Lista de Grupos de Donación ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Buscar...","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-file-excel")]),_vm._v(" Descargar CSV ")],1)],1)],1)],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"color":"light-blue darken-3"},on:{"click":function($event){return _vm.group(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Ver Grupo ")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reiniciar ")])]},proxy:true}],null,true)}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }