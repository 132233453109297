<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0">
          Contabilidad de Donaciones
        </p>
      </div>
    </v-banner>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Ver')"
              :headers="data.headers"
              :items="data.data"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
              sort-by="group"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Lista de Grupos de Donación
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon>mdi-file-excel</v-icon>
                          Descargar CSV
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- Checkbox - Status -->
              <template v-slot:[`item.status`]="{ item }">
                <v-simple-checkbox
                  v-model="item.status"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="group(item)"
                      color="light-blue darken-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-eye-outline
                    </v-icon>
                  </template>
                  <span>
                    Ver Grupo
                  </span>
                </v-tooltip>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  Reiniciar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Accounting',
  data: () => ({
    data: {
      load: true,
      headers: [
        {
          text: 'Grupo',
          value: 'group',
        },
        {
          text: 'Meta Anual',
          value: 'goal',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Acciones',
          value: 'actions',
          sortable: false,
        },
      ],
      data: [],
    },
    search: '',
  }),
  mounted() {
    this.getCurrentActions('Contabilidad Grupos');
    this.getDonationGroups();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: 'getCurrentActions',
      openSnackbar: 'Snackbar/openSnackbar',
    }),
    getDonationGroups: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + 'group')
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al obtener los datos',
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },
    group(item) {
      this.$router.push('/private/accounting/groups/' + item.id_group);
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      actions: 'getCurrentActions',
    }),
  },
};
</script>

<style></style>
